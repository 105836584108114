.mailModal .ant-modal-confirm .ant-modal-confirm-btns {
    display:none !important;
}

input::file-selector-button {
    padding: 5px 10px;
    border: thin solid lightgrey;
    border-radius: 3px;
    background-color: #1890ff;
    color: white;
}
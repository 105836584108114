.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.top-btn {
  margin-left: 6px;
  background-color: transparent;
}
/* .wrapper {
  margin: 2em;
  
} */
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.delete-btn:hover { background-color: red !important; color: white !important; border-color: white !important; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#isok textarea{
  min-width: 80%;
}

@media only screen and (min-width: 889px) {
  .content-container{
    margin: 20px;
  }
}
@media only screen and (max-width: 889px) {
  .content-container{
    margin: 5px;
  }
}

.ant-table-column-title{
  z-index: 0 !important;
}

tr.ant-table-expanded-row 
.ant-descriptions-view
table{
  width: fit-content !important;
}

.link-color{
  color:#1890ff;
}

.ant-tag-warning {
  color: #917905 !important;
  background: #ba9a031c !important;
  border-color: #fadb14 !important;
}

.ant-tag-volcano {
  color: #4b0000 !important;
  background: #4b00004f !important;
  border-color: #4b0000 !important;
}

.ant-tag-error {
  color: #db0606 !important;
  background: #db060621 !important;
  border-color: #dd0e0e !important;
}

.ant-drawer-content-wrapper {
  min-width: 320px !important;
}

.ant-form-item-label {
  text-align: left !important;
}

.ant-drawer-header {
  border-bottom: 0px !important;
}

.ant-drawer-title{
  font-size: large !important;
  font-weight: 500 !important;
}

.ant-descriptions-item-container .ant-descriptions-item-content, .ant-descriptions-item-container .ant-descriptions-item-label{
  display: block !important;
}

.button-group {
  padding-bottom:20px;
  float: right; 
}

.button-group button{
  margin-right:10px;
}

/* .content{
  margin-top:70px;
}

.content h2{
  padding: 15px 25px 5px;
} */


.rack-table tr td:first-child {
  background-color: rgb(251, 250, 250);
}

.rack-table .ant-table-thead>tr>th {
  background-color: rgb(251, 250, 250) !important;
  font-weight: bold !important;
  text-align: left !important;
}
 
.rack-btn:hover { background-color: transparent !important; }


.search-menu-wrapper {
  display: flex !important;
  margin-top: 80px ;
  align-items: center;
  justify-content: flex-end;  
  padding-right: 22px
}

.search-menu-wrapper .ant-menu {
  margin-top: 0px !important;
  line-height: 0px !important;
}
.search-menu-wrapper .ant-input-affix-wrapper {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

@media only screen and (max-width: 767px) {
  .search-menu-wrapper {
    margin-top: 0px !important;
    padding: 10px 0px !important;
    
  }

  .search-menu-wrapper .ant-input-group-wrapper {
    margin-left: 0px;
  }
}

.bookmark .ant-btn {
  padding: 0px !important;
}

[data-theme='compact'] .custom-collapse .custom-panel,
.custom-collapse .custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7 !important;
  border: 0px !important;
  border-radius: 0px;
}

.custom-panel{
  font-weight: 500;
}

.custom-collapse .custom-panel {
  background: #fec234;
  border: 0px;
}


@media(max-width: 576px) { 
  .ant-picker-panels { 
    flex-direction: column;
  } 
}
.table-button {
  display:block !important;
  margin:auto;
}

.success-block {
  background: #f5ffed;
  border: 1px solid #b7eb8f;
  border-radius: 3px;
  padding: 8px 15px;
  line-height: 1.5715;
  margin:5px auto;
}

.failed-block {
  background: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 3px;
  padding: 8px 15px;
  line-height: 1.5715;
  margin:5px auto;
}

.modal-result{
  border: 1px solid #d9d9d9;
  background: #fafafa;
  padding: 8px 15px;
}

.disabled {
  color: black !important;
}

.ant-table-tbody .ant-table-measure-row *{
  padding: 0px !important;
}

.ant-table-tbody{
  padding: 11px !important;
}
.ant-table-tbody > tr > td{
  padding: 11px !important;
} 

.ant-table-tbody>tr>td, .ant-table-thead>tr>th, .ant-table tfoot>tr>td, .ant-table tfoot>tr>th {
  padding: 11px !important;
}
.ant-descriptions-bordered .ant-descriptions-item-content, .ant-descriptions-bordered .ant-descriptions-item-label {
  padding: 7px !important;
}

.datacenter-table tr.ant-table-expanded-row .ant-descriptions-view table{
  width: min-content !important;
}
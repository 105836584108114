.ak-preloader-wrapper {
    min-height: 100px;
    position: relative;
}

.ak-preloader {
    position: relative;
    width: 52px;
    height: 56px;
}

.ak-preloader .crescent-1,
.ak-preloader .crescent-2,
.ak-preloader .crescent-3 {
    background: url('./akamai-preloader.png');
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0; left: 0;
}

@media 
(-webkit-min-device-pixel-ratio: 2), 
(min-resolution: 192dpi) { 
    .ak-preloader .crescent-1,
    .ak-preloader .crescent-2,
    .ak-preloader .crescent-3 {
        background: url('./akamai-preloader2x.png');
        background-size: auto 200%; 
    }
}

.ak-preloader .crescent-1 {
    animation: crescent 1.5s ease-in-out infinite;
    -webkit-animation: crescent 1.5s ease-in-out infinite;
    transform-origin: 32px 29px;
    -webkit-transform-origin: 32px 29px;
}
.ak-preloader .crescent-2 {
    background-position: 104px 0;
    animation: crescent 1.5s ease-in-out infinite;
    -webkit-animation: crescent 1.5s ease-in-out infinite;
    transform-origin: 34px 31px;
    -webkit-transform-origin: 34px 31px;
}
.ak-preloader .crescent-3 {
    background-position: 208px 0;
    animation: crescent 1.5s ease-in-out infinite;
    -webkit-animation: crescent 1.5s ease-in-out infinite;
    transform-origin: 36px 34px;
    -webkit-transform-origin: 36px 34px;
}

.ak-preloader-blue .crescent-1 {
    background-position: 0 -100%;
}
.ak-preloader-blue .crescent-2 {
    background-position: 104px -100%;
}
.ak-preloader-blue .crescent-3 {
    background-position: 208px -100%;
}
.ak-preloader-centered {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-left: -26px;
	margin-top: -28px;
}

@keyframes crescent
{
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

@-webkit-keyframes crescent
{
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
.horizontal-header{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 69px;
    padding: 0 20px;
    color: #fff;
    background: #ecedee;
    text-align: center;
    position: fixed;
    z-index: 2;
    width: 100%;
}

.horizontal-header h2{
    color:#000;
}
.header-text {
    text-align: left;
    width:70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.search-input-group input[type="text"]{
    border:1px solid #d9d9d9 !important;
    box-shadow: none !important;
}

.loggedin-user{
    position: fixed;
    right: 25px;
    margin:auto
}

.loggedin-user span{
    color:#fff;
}

.header-button, .header-button:hover, .header-button:active, .header-button:focus  {
    background-color: #67b2f7db ;
}

.dropdown-list li{
    padding: 12px 30px !important;
} 

.ant-drawer-content-wrapper {
    min-width:200px !important;
}

.drawer-button{
    background-color: #001628;
    border-color: #001628;
    margin-right: 10px;
    margin-top: -5px;
}

@media (min-width: 768px) {
    .drawer-button {
      display: none;
    }
  }
.sider_bottom {
      /* position: absolute !important;
      z-index: 1;
      bottom: 0; */
}

.ant-menu {
  margin-top: 18px;
}

.header-logo {
  white-space: nowrap;
  position: relative;
  margin: 15px;
  padding-bottom: 10px;
}

.header-logo span.logo {
  display: inline-block;
  vertical-align: bottom;
  overflow: hidden;
  width: 31px;
}

.header-logo span.logo>img {
  height: 35px;
}

.header-logo span.cover {
  background: #001529;
  position: absolute;
  top:0px;
  left: 14px;
  width: 17px;
  height: 17px;
}

.header-logo span.title {
  margin-left: -14px;
  font-weight: Bold;
  font-size: 16px;
  vertical-align: bottom;
  padding-bottom: 2px;
  color: #ee8822;
  font-style: Italic;
  position: relative;
}

.trigger{
  float: right;
  margin-top: 40px;
  padding: 8px 5px;
  font-size: 21px;
  background-color: #002140;
  transition: color 0.3s;
  color: white;
}
.trigger:hover {
  color: #1890ff;
}